import _Time from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

_Time.extend(utc);
_Time.extend(RelativeTime);

export const Time = _Time;

export type Time = _Time.Dayjs;

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
