declare var window: {
  readonly __REDUX_DEVTOOLS_EXTENSION__?: ReduxDevToolsExtension;
};

export const integrateReduxDevtools = (namespace: string): null | ReduxDevtools =>
  window.__REDUX_DEVTOOLS_EXTENSION__?.connect({ name: `ArtistsCard Web Next ${namespace}` }) ?? null;

type ReduxDevToolsExtension = {
  readonly connect: (config: ReduxDevToolsExtensionConfig) => ReduxDevtools;
  readonly open: () => void;
  readonly disconnect: () => void;
  readonly listen: () => void;
  readonly notifyErrors: (error: any) => void;
  readonly send: () => void;
  readonly updateStore: () => void;
};

type ReduxDevToolsExtensionConfig = {
  readonly instanceId?: string;
  readonly name?: string;
  readonly actionCreators?: Record<string, Function>;
  readonly latency?: number;
  readonly maxAge?: number;
  readonly trace?: boolean | Function;
  readonly traceLimit?: number;
  readonly serialize?:
    | boolean
    | {
        readonly options?: Record<string, any>;
        readonly replacer?: (key: string, value: any) => any;
        readonly reviver?: (key: string, value: any) => any;
        readonly immutable?: () => void;
      };
  readonly actionSanitizer?: (action: any) => any;
  readonly stateSanitizer?: (state: any) => any;
  readonly actionsBlacklist?: string | readonly string[];
  readonly actionsWhitelist?: string | readonly string[];
  readonly predicate?: (state: any, action: any) => void;
  readonly shouldRecordChanges?: boolean;
  readonly pauseActionType?: string;
  readonly autoPause?: boolean;
  readonly shouldStartLocked?: boolean;
  readonly shouldHotReload?: boolean;
  readonly shouldCatchErrors?: boolean;
  readonly features?: {
    readonly pause?: boolean;
    readonly lock?: boolean;
    readonly persist?: boolean;
    readonly export?: boolean;
    readonly import?: string;
    readonly jump?: boolean;
    readonly skip?: boolean;
    readonly reorder?: boolean;
    readonly dispatch?: boolean;
    readonly test?: boolean;
  };
};

export type ReduxDevtools = {
  readonly init: (initialState: any) => void;
  readonly subscribe: (subscriber: Subscriber) => void;
  readonly unsubscribe: () => void;
  readonly send: (action: any, state: any) => void;
  readonly error: (message: string) => void;
};

type Subscriber = (message: Message) => void;

type Message = {
  readonly id: string;
  readonly source: "@devtools-extension";
  readonly state?: string;
  readonly type: string;
  readonly payload: any;
};
