import { Reducer } from "react";
import { Immutable, enableMapSet } from "immer";

enableMapSet();

export { combineReducers } from "./combineReducers";
export { createDuck } from "./createDuck";
export { createStore } from "./createStore";

export type Store<S> = S extends Reducer<infer SS, any> ? Immutable<SS> : never;
