export type Key = string;

export abstract class ID {
  readonly key: Key;

  constructor(prefix: string, raw: number | string) {
    this.key = `${prefix}.${raw}`;
  }
}

export class NumericID extends ID {
  constructor(prefix: string, readonly raw: number) {
    super(prefix, +raw);

    this.raw = +raw;
  }
}

export class StringID extends ID {
  constructor(prefix: string, readonly raw: string) {
    super(prefix, raw.toString());

    this.raw = raw.toString();
  }
}
