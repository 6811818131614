/* eslint-disable */
// ref. https://github.com/epoberezkin/fast-deep-equal

// do not edit .js files directly - edit src/index.jst

export const fastDeepEqual = (a: any, b: any): boolean => {
  if (a === b) return true;

  if (a && b && typeof a == "object" && typeof b == "object") {
    if (a.constructor !== b.constructor) return false;

    var length, i, keys;
    if (Array.isArray(a)) {
      length = a.length;
      if (length != b.length) return false;
      for (i = length; i-- !== 0; ) if (!fastDeepEqual(a[i], b[i])) return false;
      return true;
    }

    // 으아악, fast-deep-equal은 Map의 equality는 확인해 주지 않네요.
    if (a instanceof Map) {
      if (b instanceof Map) {
        if (a.size !== b.size) {
          return false;
        }
        length = a.size;
        const c = Array.from(a);
        const d = Array.from(b);
        for (i = length; i-- !== 0; ) {
          if (c[i][0] !== d[i][0]) return false;
          if (!fastDeepEqual(c[i][1], d[i][1])) return false;
        }
        return true;
      }
      return false;
    }

    // 으아악, fast-deep-equal은 Set의 equality는 확인해 주지 않네요.
    if (a instanceof Set) {
      if (b instanceof Set) {
        if (a.size !== b.size) {
          return false;
        }
        length = a.size;
        const c = Array.from(a);
        const d = Array.from(b);
        for (i = length; i-- !== 0; ) if (!fastDeepEqual(c[i], d[i])) return false;
        return true;
      }
      return false;
    }

    if (a.constructor === RegExp) return a.source === b.source && a.flags === b.flags;
    if (a.valueOf !== Object.prototype.valueOf) return a.valueOf() === b.valueOf();
    if (a.toString !== Object.prototype.toString) return a.toString() === b.toString();

    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) return false;

    for (i = length; i-- !== 0; ) if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

    for (i = length; i-- !== 0; ) {
      var key = keys[i];

      if (key === "_owner" && a.$$typeof) {
        // React-specific: avoid traversing React elements' _owner.
        //  _owner contains circular references
        // and is not needed when comparing the actual elements (and not their owners)
        continue;
      }

      if (!fastDeepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  // true if both NaN, false otherwise
  return a !== a && b !== b;
};
